<template>
	<div class="element-selection">
		<button
			@click="
				$emit('input', 1);
				$emit('add');
			"
		>
			<div class="content-container">
				<div class="selection">
					<div class="filled"></div>
					<div class="dashed-empty"></div>
					<div class="dashed-empty"></div>
					<div class="dashed-empty"></div>
				</div>
				<h1 class="title">Auswahl-Element</h1>
				<p class="subtitle">1 Element auswählen</p>
			</div>
		</button>
		<button
			@click="
				$emit('input', 2);
				$emit('add');
			"
		>
			<div class="content-container">
				<div class="selection">
					<div class="filled"></div>
					<div class="solid-empty"></div>
					<div class="solid-empty"></div>
					<div class="solid-empty"></div>
				</div>
				<h1 class="title">Checkbox-Element</h1>
				<p class="subtitle">Mehrere Elemente auswählen</p>
			</div>
		</button>
		<button
			@click="
				$emit('input', 3);
				$emit('add');
			"
		>
			<div class="content-container">
				<div class="selection">
					<div class="text">
						<p>Lorem</p>
					</div>
				</div>
				<h1 class="title">Text-Element</h1>
				<p class="subtitle">Freien Text oder Wert eingeben</p>
			</div>
		</button>
	</div>
</template>

<script>
export default {
	name: 'ElementSelector',
	props: {
		value: Number,
		project: Object,
		page: Object,
		survey: Object
	}
};
</script>

<style lang="scss" scoped>
.element-selection {
	@include flex-row;

	margin-bottom: 40px;

	button {
		margin-right: 30px;

		padding-top: 10px;
		padding-bottom: 10px;

		&:nth-child(3n) {
			margin-right: 0px;
		}

		@include flex-column-3;

		cursor: pointer;

		border: none;
		background: rgba(253, 195, 0, 0.2) 0% 0% no-repeat padding-box;
		border-radius: 4px;

		.content-container {
			width: 100%;
			height: 100%;

			.selection {
				width: 140px;
				height: 75px;
				margin: 0 auto;

				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(3, 30px);
				grid-gap: 10px;

				div {
					height: 30px;

					&.filled {
						background: $unnamed-color-fdc300 0% 0% no-repeat padding-box;
						border-radius: 2px;
					}

					&.dashed-empty {
						border: 1px dashed $unnamed-color-fdc300;
						border-radius: 4px;
					}

					&.solid-empty {
						border: 1px solid $unnamed-color-fdc300;
						border-radius: 4px;
					}
				}

				.text {
					width: 140px;
					height: 75px;
					margin: 0 auto;

					border: 1px solid $unnamed-color-fdc300;
					border-radius: 4px;

					@include flex-center;

					p {
						@include italic-text-22;
						color: $unnamed-color-fdc300;
					}
				}
			}

			.title {
				margin: 0;

				@include bold-text-uppercase-22;
				color: $unnamed-color-fdc300;
			}

			.subtitle {
				@include normal-text-15;
				color: $unnamed-color-fdc300;
			}
		}
	}
}
</style>
